import type { UIEvent } from "react";

import { ScreenerLocationStatus } from "@/types/screeners";
import type { Nullable } from "@/types/utils";

enum Rating {
  High = "High",
  Medium = "Medium",
  Low = "Low",
}

const badgeColors: {
  [key: string]: {
    [key: string]: {
      background: string;
      text: string;
    };
  };
} = {
  qualifyingPassRate: {
    high: { background: "#b9e7df", text: "#0f6b5c" },
    low: { background: "#f5c9c7", text: "#922317" },
  },
  overallFit: {
    [Rating.High]: { background: "#b9e7df", text: "#0f6b5c" },
    [Rating.Medium]: { background: "#fde68a", text: "#e3573d" },
    [Rating.Low]: { background: "#f5c9c7", text: "#922317" },
  },
  competency: {
    [Rating.High]: { background: "#139F88", text: "#139F88" },
    [Rating.Medium]: { background: "#e3573d", text: "#e3573d" },
    [Rating.Low]: { background: "#F04250", text: "#F04250" },
  },
  isVisibleOnPdf: {
    default: {
      background: "#E6E6E6",
      text: "#666666",
    },
  },
  status: {
    [ScreenerLocationStatus.Live]: { background: "#B8ECE3", text: "#0F7C6A" },
    [ScreenerLocationStatus.Mixed]: { background: "#E9F7F5", text: "#7CA6A0" },
    [ScreenerLocationStatus.Paused]: { background: "#F2F2F2", text: "#999999" },
  },
};

export const getBadgeColor = (value: string, type: string) => {
  if (type === "qualifyingPassRate") {
    if (value === "N/A") {
      return badgeColors.status[ScreenerLocationStatus.Paused];
    }
    return Number(value) >= 75
      ? badgeColors.qualifyingPassRate.high
      : badgeColors.qualifyingPassRate.low;
  }

  if (type.startsWith("isVisibleOnPdf")) {
    return badgeColors.isVisibleOnPdf.default;
  }

  return badgeColors[type]?.[value] || {};
};

export const rowColorCodes = {
  red: "#ffd6d4",
  orange: "#ffedd6",
  yellow: "#ffffdc",
  green: "#d6e8d2",
  blue: "#ceceff",
  purple: "#e9d1e7",
};
export const borderColorCodes = {
  red: "#ff0000",
  orange: "#ffa500",
  yellow: "#ffff00",
  green: "#008000",
  blue: "#0000ff",
  purple: "#800080",
};

export const getJobNameWithDisplayName = (
  jobName?: string,
  internalName?: string,
) =>
  jobName || internalName
    ? `${jobName}${internalName ? ` | ${internalName}` : ""}`
    : "";

export const stopPropagationWrapper =
  <T extends UIEvent>(callback?: (event: T) => void) =>
  (e: T) => {
    e.stopPropagation();

    callback?.(e);
  };

// The actual color values have changed over time. Rather than migrate them all
// in the DB anytime there is a change we can just keep a map of generic names
// to current color codes.
const HIGHLIGHT_COLOR_MAP = {
  blue: "#89d8f1",
  green: "#18bfa0",
  orange: "#d97601",
  purple: "#784dd5",
  red: "#bb0100",
  yellow: "#f6d03e",
} as const;

export type Colors = Nullable<keyof typeof HIGHLIGHT_COLOR_MAP>;

export const getCodeForHighlightColor = (color?: Colors) =>
  color ? HIGHLIGHT_COLOR_MAP[color] : "transparent";

export const HIGHLIGHT_OPTIONS: {
  value: Colors;
  label: string;
}[] = [
  { value: "red", label: "Red" },
  { value: "orange", label: "Orange" },
  { value: "yellow", label: "Yellow" },
  { value: "green", label: "Green" },
  { value: "blue", label: "Blue" },
  { value: "purple", label: "Purple" },
  { value: null, label: "None" },
] as const;

export const getNameForHighlightColor = (colorCode: string) => {
  const mapName = Object.keys(HIGHLIGHT_COLOR_MAP).find(
    (colorName) =>
      HIGHLIGHT_COLOR_MAP[colorName as keyof typeof HIGHLIGHT_COLOR_MAP] ===
      colorCode,
  );
  const accessibleName = HIGHLIGHT_OPTIONS.find(
    ({ value }) => value === mapName,
  );

  return accessibleName?.label ?? "Unknown color name";
};

export const getTableViewContentHeightClass = (isInternal = false) =>
  isInternal ? "h-[calc(100dvh_-_154px)]" : "h-[calc(100dvh_-_106px)]";
